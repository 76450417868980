
.reactMarkDown strong {
    color:  #ff6347 
  }

.reactMarkDown a {
    color:  rgb(79 70 229);
  }

  .reactMarkDown a:hover {
    color: rgb(96 165 250);
    
  }
