
.zig-zag-section {
  background-color: #CE7BB0;
}

.zig-zag-section .font-architects-daughter {
  font-size: 2em;
}

.zig-zag-section .reactMarkDown {
  color:black;
}

.zig-zag-section .reactMarkDown strong {
  color: black;
}

.zig-zag-section .reactMarkDown a {
  color: #7900FF;
  font-weight: 900;
}

.zig-zag-section .reactMarkDown a:hover {
  color: #FFF
}

.scissor-img-left {
  transform: rotate(-5deg);
}

.scissor-img-right {
  transform: rotate(10deg);
}

.slogan {
  size: 3rem;
  color: royalblue;  
}

.icon-slogan {
  margin: 0 1rem 0;
}

#rock {
  color: red;
}

#paper {
  color: orange;
}

#scissors {
  color: yellow;
}

#lizard {
  color: green;
}

#spock {
  color: indigo;  
}