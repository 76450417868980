
.faq-section {
  background-color: #CE7BB0;
}

.faq-section .reactMarkDown {
  color:black;
}

.faq-section .reactMarkDown strong {
  color: black;
}

.faq-section .reactMarkDown a {
  color: #7900FF;
  font-weight: 900;
}

.faq-section .reactMarkDown a:hover {
  color: #FFF
}

.question-faq {
  color: #7900FF;
  font-weight: bold;
  font-size: 2rem;
}

.faq-summary {
  text-align: center;
  font-family: 'Montserrat', sans-serif;
  line-height: 1.7;
  font-size: 1.3rem;
}

.faq-section strong {
  color: black;
}

.faq-summary a {
  color: #7900FF;
  font-weight: 900;
}

.faq-summary a:hover {
  color: #FFF
}

