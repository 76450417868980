.react-cloak {
  display: none !important;
}

.btn-ml {
  margin-left:10px;
}

.btn-mr {
  margin-right:10px;
}

.info-minting-text {
  font-size: x-large;
  text-align: center;
  overflow-wrap:break-word;
  font-weight: bold;
  color:yellow;
}

.info-json-text {
  font-size: small;
  text-align: center;
  overflow-wrap:break-word;
  color:green;
}
