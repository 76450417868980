.info-countdown-text {
  text-align: center;
  overflow-wrap:break-word;
  font-weight: bold;
  color:red;
}

.reactMarkDown {
  text-align: left;
  font-family: 'Montserrat', sans-serif;
  line-height: 1.7;
  text-indent: 1rem;
}

.reactMarkDown strong {
  color:  #ff6347;
}

.reactMarkDown a {
  color:  rgb(79 70 229);
}

.reactMarkDown a:hover {
  color: rgb(96 165 250);
}

h4.title-feature {
  align-self: flex-start;
}

.reactMarkDown.features {
  text-indent: 0rem;
}