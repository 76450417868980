
.mission-section {
  background-color: #CE7BB0;;
}

.mission-section .font-architects-daughter {
  font-size: 2em;
}

.mission-section .reactMarkDown {
  color:black;
}

.mission-section .reactMarkDown strong {
  color: black;
}

.mission-section .reactMarkDown a {
  color: #7900FF;
  font-weight: 900;
}

.mission-section .reactMarkDown a:hover {
  color: #FFF
}

.reactMarkDown.mission {
  font-weight: 400;
  font-size: 1.5rem;
  color: black;
  text-align: center;
  font-style: italic;
}

.reactMarkDown.vision {
  font-weight: 600;
  font-size: 1.5rem;
  color: black;
  text-align: center;
}
